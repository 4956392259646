import { QueryOptions } from '@tanstack/react-query';
import { get } from 'lodash';
import { fetch, ListEntityResponse, Paginated } from '../index';
import { mapQueryKeyToSearchParams, QueryMethod } from '../utils';
import {
  CycleToWorkSettings,
  GlobalDetailsSettings,
} from '../../views/account-settings/requestObjects/intex';
import {
  InviteEmployerDto,
  PartialTenantSettingsDto,
  TenantDto,
  TenantSettingsDto,
  AdminUpdateTenantRequest,
  UpdateTenantRequest,
  GlobalDetailsRequest,
  FullDetailsDto,
  TenantSettingsExtendedDto, TenantInvitationInfo,
} from './dto';

export async function getTenantSettings() {
  const response = await fetch<TenantSettingsExtendedDto>(
    '/gogeta-api/v1/tenant/settings',
    QueryMethod.GET,
  );

  return response;
}

export async function updateGlobalTenantSettings(data: GlobalDetailsRequest) {
  const response = await fetch<TenantSettingsDto>(
    '/gogeta-api/v1/tenant/global-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}

export interface UpdateCycleToWorkSettingsRequest
  extends Omit<CycleToWorkSettings, 'currentYearSchemeLimit' | 'certificateApplicationDatePeriod'> {
  certificateApplicationDatePeriod: {
    startDate: string;
    endDate: string;
    active: boolean;
  };
}

export interface UpdateWelcomeSettingsRequest {
  companyDescription: string;
}

export async function updateCycleToWorkTenantSettings(data: UpdateCycleToWorkSettingsRequest) {
  const response = await fetch<TenantSettingsDto>(
    '/gogeta-api/v1/tenant/cycle-to-work-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}

export async function updateWelcomeSettings(data: UpdateWelcomeSettingsRequest) {
  const response = await fetch<UpdateWelcomeSettingsRequest>(
    '/gogeta-api/v1/tenant/welcome-settings',
    QueryMethod.PUT,
    data,
  );

  return response;
}

export async function getPartialTenantSettings(): Promise<PartialTenantSettingsDto> {
  const response = (await fetch(
    '/gogeta-api/v1/tenant/partial-settings',
    QueryMethod.GET,
  )) as PartialTenantSettingsDto;

  return response;
}

export async function getAllTenants({ queryKey }: QueryOptions) {
  return await fetch<Paginated<TenantDto>>(
    '/gogeta-api/v1/tenant',
    QueryMethod.GET,
    null,
    mapQueryKeyToSearchParams(queryKey),
  );
}

export async function getTenantSettingsById({ queryKey }: QueryOptions) {
  const tenantId = get(queryKey, '0.1');
  if (!tenantId) {
    throw new Error('Missing tenant id');
  }

  return await fetch<FullDetailsDto>(`/gogeta-api/v1/tenant/${tenantId}/settings`, QueryMethod.GET);
}

export async function getInvitationTenantSettingsById({ queryKey }: QueryOptions) {
  const slug = get(queryKey, '0.1');
  if (!slug) {
    throw new Error('Missing tenant id');
  }

  return await fetch<TenantInvitationInfo>(`/gogeta-api/v1/tenant/invitation-settings?accountNumber=${slug}`, QueryMethod.GET);
}

export async function confirmTenantRegistration(tenantId: number) {
  return await fetch<{ userIds: number[]; tenantAccountNumber: string }>(
    `/gogeta-api/v1/tenant/confirm-registration`,
    QueryMethod.POST,
    { tenantId },
  );
}

export async function inviteEmployer(body: InviteEmployerDto) {
  return await fetch(`/gogeta-api/v1/tenant/invite-employer`, QueryMethod.POST, body);
}

export async function adminUpdateTenantById(tenantId: string, body: AdminUpdateTenantRequest) {
  return await fetch(`/gogeta-api/v1/tenant/admin/${tenantId}`, QueryMethod.PUT, body);
}

export async function updateTenant(body: UpdateTenantRequest): Promise<TenantSettingsDto> {
  return await fetch(`/gogeta-api/v1/tenant`, QueryMethod.PUT, body);
}
